<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: false }">
        <div id="sticky-heading-container" class="heading-container sticky">
            <h1 class="title">{{$t('admin.title')}}</h1>
        </div>
        
        <div id="with-sticky-header" class="with-sticky-header">
            <div class="table-container scrollable with-sticky-header nomargin-bottom">
                <div class="filter-sort-container">
                    <div class="item-container">
                        <p class="label">{{ $t('admin.work_provider_offers.sort.title') }}</p>

                        <multiselect    v-model="sort_by"
                                        id="sort-by"
                                        :options="sort_by_options"
                                        :close-on-select="true"
                                        :multiple="false"
                                        :allowEmpty="false"
                                        :searchable="false"

                                        :select-label="$t('language.multiselect.label.select')"
                                        :selected-label="$t('language.multiselect.label.selected')"
                                        :deselect-label="$t('language.multiselect.label.deselect')"
                                        :custom-label="translate_sort_by">
                        </multiselect>
                    </div>

                    <div class="item-container min">
                        <span :class="['cta cta-option', { active: deleted }]" @click="deleted = !deleted">Deleted</span>
                    </div>
                </div>

                <table class="nomargin-bottom">
                    <tr>
                        <th>{{$t('admin.work_provider_offers.table.th.name')}}</th>
                        <th>{{$t('admin.work_provider_offers.table.th.email')}}</th>
                        <th>{{$t('admin.work_provider_offers.table.th.offers')}}</th>
                        <th>{{$t('admin.work_provider_offers.table.th.created_at')}}</th>
                        <th>{{$t('admin.work_provider_offers.table.th.last_login')}}</th>
                        <th>{{$t('admin.work_provider_offers.table.th.payment_method_activated')}}</th>
                        <th>{{$t('admin.work_provider_offers.table.th.account_verified')}}</th>
                        <th>{{$t('admin.work_provider_offers.table.th.deleted')}}</th>
                    </tr>
                    
                    <tr v-for="work_provider in sorted_work_providers" :key="work_provider.work_provider_id">
                        <td :class="['bold no-wrap']">{{ work_provider.alternate_name }}</td>
                        <td class="no-wrap">{{ work_provider.email }}</td>
                        <td class="no-wrap">{{ work_provider.work_provider_paid_offers_active_count }} / {{ work_provider.unpublished_work_provider_offers_active_count }}</td>

                        <td class="no-wrap">{{ work_provider.created_at | moment("DD/MM/YYYY") }}</td>
                        <td class="no-wrap">{{ work_provider.last_login | moment("DD/MM/YYYY, H:mm") }}</td>

                        <td class="no-wrap" v-if="work_provider.payment_method_activated"><font-awesome-icon class="success" :icon="['fa', 'circle-check']" /></td>
                        <td class="no-wrap" v-else><font-awesome-icon class="warning" :icon="['fa', 'circle-exclamation']" /></td>
                        
                        <td class="no-wrap" v-if="work_provider.account_verified"><font-awesome-icon class="success" :icon="['fa', 'circle-check']" /></td>
                        <td class="no-wrap" v-else><font-awesome-icon class="warning" :icon="['fa', 'circle-exclamation']" /></td>
                        
                        <td class="no-wrap" v-if="!work_provider.deleted"><font-awesome-icon class="success" :icon="['fa', 'circle-xmark']" /></td>
                        <td class="no-wrap" v-else><font-awesome-icon class="error" :icon="['fa', 'circle-check']" /></td>
                    </tr>
                    
                    <tr class="no-hover">
                        <td v-if="loading" class="loading" colspan="8"></td>
                    </tr>
                </table>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../components/Layout.vue';

export default {
    name: 'AdminDashboard',

    components: {
        Layout,
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    watch: {
        sort_by: function() {
            const self = this;
            self.sort();
        }
    },

    data() {
        return {
            routeName: 'admin',
            classes: { dashboard: true, admin: true},

            sort_by: 'last_login',
            sort_by_options: ['last_login', 'created_at'],

            deleted: false,

            work_providers: [],
            loading: false,
        }
    },

    computed: {
        ...mapGetters([
            'currentProfile',
            'is_admin'
        ]),

        sorted_work_providers: function() {
            const self = this;
            return self.work_providers.filter((a) => a.deleted === self.deleted).sort((a, b) => {
                return a[self.sort_by] < b[self.sort_by] ? 1 : -1;
            });
        }
    },

    methods: {
        get_admin_work_providers: function() {
            const self = this;
            self.loading = true;

            self.$axios
                .get(`admin/work-providers`)
                .then(response => {
                    if(response.status == 200){
                        self.loading = false;
                        self.work_providers = response.data;
                        self.sort();
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                    
                    self.loading = false;
                });
        },

        translate_sort_by: function(option) {
            const self = this;
            return self.$t(`admin.work_provider_offers.sort.options.${option}`);
        }
    },

    mounted() {
        const self = this; 
        if(self.is_admin) {
            self.get_admin_work_providers();
        }
    }
}
</script>

